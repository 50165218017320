import * as React from 'react';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useAuthContext } from 'contexts/AuthContext';
import { apiUrl } from 'utils/apiUrl';
import { useRestApi } from './useRestApi';

// Permissions used in this application. This is a type to avoid typos for
// permission names.

export type AuthorizationService =
  | 'fnds-group'
  | 'group-mapping'
  | 'identity-provider'
  | 'person'
  | 'tenant'
  | 'il4'
  | 'feature-flags'
  | 'mobile'
  | 'license'
  | 'blackboard.management'
  | 'client'
  | 'dda'
  | 'blackboard.sites';
export type AuthorizationPermission = 'create' | 'delete' | 'read' | 'update' | 'access' | 'view';

export interface PermissionsApiResponse {
  permissions: string[];
}

export interface AudienceResult {
  audience: string;
  tenantId: string;
}

export interface PermissionResult {
  /**
   * Any error state occurring while permissions lookup was being done.
   */
  error?: Error;
  /**
   * Returns whether the current user has a particular permission according to the
   * authorization authority service. This is pessimistic by design; while the API
   * response is loading or if the API response failed, a false value is returned.
   */
  hasPermission: (service: AuthorizationService, permission: AuthorizationPermission) => boolean;
  /**
   * Are permissions being loaded from the API now?
   */
  loading: boolean;
  /**
   * All permissions the user has.
   */
  permissions: {
    service: AuthorizationService;
    permission: AuthorizationPermission;
  }[];
}

// Memoizing this outside the hook.

const badApiResponseError = new Error('Permissions in authorization API not present or malformed');

export function useAuthorization(): PermissionResult {
  const { cognito } = useAppConfigContext();
  const { accessToken, loading: authLoading } = useAuthContext();
  const [permsFetched, setPermsFetched] = React.useState(false);
  const {
    data: permData,
    error: permError,
    loading: permLoading,
    fetch: fetchPerms,
  } = useRestApi<PermissionsApiResponse>(
    apiUrl('authz', `tenants/${cognito?.tenantId}/permissions`),
    { manual: true },
  );
  const hasPermission = React.useCallback(
    (service: AuthorizationService, permission: AuthorizationPermission) => {
      if (permError || !Array.isArray(permData?.permissions)) {
        return false;
      }

      return permData?.permissions.includes(`${service}.${permission}`) ?? false;
    },
    [permData, permError],
  );

  // When we have an access token, fetch permissions.

  React.useEffect(() => {
    if (accessToken && cognito?.tenantId && !permsFetched) {
      setPermsFetched(true);

      // Important that we use the cache for this call--avoids intermediate
      // loading statuses and flashes of "you don't have permission" messages.

      fetchPerms(undefined, { useCache: true });
    }
  }, [accessToken, cognito, fetchPerms, permsFetched]);

  // If auth has loaded but we have no access token, the user must be
  // unauthenticated. `loading` must be false so that the UI doesn't block.

  if (!authLoading && !accessToken) {
    return { hasPermission, permissions: [], loading: false };
  }

  // If something went wrong talking to the API, return that error.

  if (permError) {
    return {
      hasPermission,
      error: permError,
      permissions: [],
      loading: false,
    };
  }

  // If anything we depend on is loading, return that status. The check on
  // permData is to handle the render cycle where audience finishes loading and
  // the effect above is kicked off. At that one instance, permLoading is false
  // so we need an additional check.

  if (authLoading || permLoading || !permData) {
    return { hasPermission, permissions: [], loading: true };
  }

  // If the permissions response we got back looks malformed, return that error.

  if (permData && (!permData?.permissions || !Array.isArray(permData?.permissions))) {
    return { hasPermission, error: badApiResponseError, loading: false, permissions: [] };
  }

  // We have permissions.

  return {
    hasPermission,
    loading: false,
    permissions: permData!.permissions.map((permission) => {
      const parts = permission.split('.');

      return { service: parts[0], permission: parts[1] } as {
        service: AuthorizationService;
        permission: AuthorizationPermission;
      };
    }),
  };
}
