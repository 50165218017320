import * as React from 'react';
import {
  createStyles,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@bb-ui/react-library';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { FleetDialogData } from 'App.types';
import useRestApi from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';

const styles = (theme: Theme) =>
  createStyles({
    createFleetInput: {
      margin: theme.spacing(1, 0, 1),
    },
    radioButton: {
      marginLeft: theme.spacing(-1),
    },
  });

export const useStyles = makeStyles(styles);

const emptyFleetData: FleetDialogData = {
  name: '',
  isIL4: false,
  stage: '',
  fleetId: '',
  region: '',
  accountId: '',
  crowdStrikeLicenseKey: '',
  scoreRecommendationsURL: '',
  institutionPagesURL: '',
  sharedSecret: '',
};

export interface CreateFleetDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateFleetDialog: React.FunctionComponent<CreateFleetDialogProps> = (props) => {
  const { id, open, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('tenancy', 'fleets'), { manual: true });

  const [showErrors, setShowErrors] = React.useState(false);
  const [formData, setFormData] = React.useState<FleetDialogData>(emptyFleetData);
  const [radioChoice, setRadioData] = React.useState<boolean>(false);
  const classes = useStyles(props);

  const validateField = (fieldValue: string | boolean, fieldName: string): string => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      return t(`fleetService.createFleetDialog${fieldName}ErrorMessage`);
    }
    return '';
  };

  const getFieldError = (fieldKey: keyof FleetDialogData): string => {
    return showErrors ? validateField(formData[fieldKey], fieldKey) : '';
  };

  function create(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);
    const errors = {
      name: validateField(formData.name, 'name'),
      stage: validateField(formData.stage, 'stage'),
      fleetId: validateField(formData.fleetId, 'fleetId'),
      region: validateField(formData.region, 'region'),
      accountId: validateField(formData.accountId, 'accountId'),
      crowdStrikeLicenseKey: validateField(formData.crowdStrikeLicenseKey, 'crowdStrikeLicenseKey'),
      scoreRecommendationsURL: validateField(
        formData.crowdStrikeLicenseKey,
        'scoreRecommendationsURL',
      ),
      institutionPagesURL: validateField(formData.crowdStrikeLicenseKey, 'institutionPagesURL'),
      sharedSecret: validateField(formData.crowdStrikeLicenseKey, 'sharedSecret'),
    };
    const hasErrors = Object.values(errors).some((error) => error !== '');
    if (hasErrors) {
      return;
    }
    doPost(formData);
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    setFormData(emptyFleetData);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('fleetService.createFleetSuccessMessage'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      onSuccess();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    formData,
    onSuccess,
    succeededRequests.length,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('fleetService.createFleetErrorMessage', {
          message: failedRequests[0].error.message,
          name: formData.name,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    formData.name,
    t,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('fleetService.addFleetDialogTitle')}
      </DialogTitle>
      <form onSubmit={create} noValidate data-testid="create-fleet-form">
        <DialogContent>
          <TextField
            id={`${id}-fleet-name`}
            data-testid={`${id}-fleet-name`}
            label={t('fleetService.name')}
            onChange={(event) => setFormData({ ...formData, name: event.target.value.trim() })}
            fullWidth
            placeholder={t('fleetService.namePlaceholder')}
            required
            error={!!getFieldError('name')}
            helperText={getFieldError('name')}
            className={classes.createFleetInput}
          />
          <FormLabel>{t('fleetService.isIL4Dialog')}</FormLabel>
          <RadioGroup
            hasCustomLegend
            id={`${id}-is-il4-options`}
            data-testid={`${id}-is-il4-options`}
            name="is-fleet-il4"
            onChange={(event) => {
              setFormData({ ...formData, isIL4: event.target.value === 'yes' });
              setRadioData(event.target.value === 'yes');
            }}
          >
            <FormControlLabel
              className={classes.radioButton}
              checked={radioChoice === true}
              value="yes"
              control={<Radio />}
              label={t('global.yes')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={radioChoice === false}
              value="no"
              control={<Radio />}
              label={t('global.no')}
            />
          </RadioGroup>
          <TextField
            id={`${id}-fleet-stage`}
            data-testid={`${id}-fleet-stage`}
            label={t('fleetService.stage')}
            onChange={(event) => setFormData({ ...formData, stage: event.target.value.trim() })}
            fullWidth
            placeholder={t('fleetService.stagePlaceholder')}
            required
            error={!!getFieldError('stage')}
            helperText={getFieldError('stage')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-fleetId`}
            data-testid={`${id}-fleet-fleetId`}
            label={t('fleetService.fleetId')}
            onChange={(event) => setFormData({ ...formData, fleetId: event.target.value.trim() })}
            fullWidth
            placeholder={t('fleetService.fleetIdPlaceholder')}
            required
            error={!!getFieldError('fleetId')}
            helperText={getFieldError('fleetId')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-region`}
            data-testid={`${id}-fleet-region`}
            label={t('fleetService.region')}
            onChange={(event) => setFormData({ ...formData, region: event.target.value.trim() })}
            fullWidth
            placeholder={t('fleetService.regionPlaceholder')}
            required
            error={!!getFieldError('region')}
            helperText={getFieldError('region')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-accountId`}
            data-testid={`${id}-fleet-accountId`}
            label={t('fleetService.accountId')}
            onChange={(event) => setFormData({ ...formData, accountId: event.target.value.trim() })}
            fullWidth
            placeholder={t('fleetService.accountIdPlaceholder')}
            required
            error={!!getFieldError('accountId')}
            helperText={getFieldError('accountId')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-crowdStrikeLicenseKey`}
            data-testid={`${id}-fleet-crowdStrikeLicenseKey`}
            label={t('fleetService.crowdStrikeLicenseKey')}
            onChange={(event) =>
              setFormData({ ...formData, crowdStrikeLicenseKey: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('fleetService.crowdStrikeLicenseKeyPlaceholder')}
            required
            error={!!getFieldError('crowdStrikeLicenseKey')}
            helperText={getFieldError('crowdStrikeLicenseKey')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-scoreRecommendationsURL`}
            data-testid={`${id}-fleet-scoreRecommendationsURL`}
            label={t('fleetService.scoreRecommendationsURL')}
            onChange={(event) =>
              setFormData({ ...formData, scoreRecommendationsURL: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('fleetService.scoreRecommendationsURLPlaceholder')}
            required
            error={!!getFieldError('scoreRecommendationsURL')}
            helperText={getFieldError('scoreRecommendationsURL')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-institutionPagesURL`}
            data-testid={`${id}-fleet-institutionPagesURL`}
            label={t('fleetService.institutionPagesURL')}
            onChange={(event) =>
              setFormData({ ...formData, institutionPagesURL: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('fleetService.institutionPagesURLPlaceholder')}
            required
            error={!!getFieldError('institutionPagesURL')}
            helperText={getFieldError('institutionPagesURL')}
            className={classes.createFleetInput}
          />
          <TextField
            id={`${id}-fleet-sharedSecret`}
            data-testid={`${id}-fleet-sharedSecret`}
            label={t('fleetService.sharedSecret')}
            onChange={(event) =>
              setFormData({ ...formData, sharedSecret: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('fleetService.sharedSecretPlaceholder')}
            required
            error={!!getFieldError('sharedSecret')}
            helperText={getFieldError('sharedSecret')}
            className={classes.createFleetInput}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-create-fleet-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-create-fleet-dialog"
            disabled={loadingRequests}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateFleetDialog;
