import * as React from 'react';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';
import { Add } from '@bb-ui/icons/dist/small';
import CreateFleetDialog from 'dialogs/CreateFleetDialog';
import { makeStyles } from '@bb-ui/react-library';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
}));

export interface CreateFleetButtonProps {
  onReload: () => void;
}

export const CreateFleetButton: React.FunctionComponent<CreateFleetButtonProps> = ({
  onReload,
}) => {
  const { t } = useTranslation();
  const [createButtonDialogOpen, setCreateButtonDialogOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <PrimaryButton
        startIcon={<Add />}
        data-testid="header-add-button-fleet-service"
        onClick={() => setCreateButtonDialogOpen(true)}
        className={classes.button}
      >
        {t('fleetService.addFleetButton')}
      </PrimaryButton>
      <CreateFleetDialog
        id="create-fleet-dialog"
        onClose={() => setCreateButtonDialogOpen(false)}
        onSuccess={onReload}
        open={createButtonDialogOpen}
      />
    </>
  );
};
