import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
  FeatureFlagVisibility,
  putTenantsFlagValue,
} from '@bb-config-ui/feature-flags';
import { Information } from '@bb-ui/icons/dist/small';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  DialogActions,
  DefaultButton,
  PrimaryButton,
  Radio,
  OutlineButton,
  Tooltip,
} from '@bb-ui/react-library';
import {
  FieldsetProps,
  LegendProps,
} from '@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAuthContext } from 'contexts/AuthContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TenantData } from '../../../App.types';
import { FeatureFlagValuesFollowupDialog } from '../FeatureFlagGeneral/FeatureFlagValuesFollowupDialog';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editHeadingText: {
      marginRight: theme.spacing(1),
    },
    radioButton: {
      marginLeft: theme.spacing(-1),
    },
    sectionDescription: {
      marginLeft: theme.spacing(3),
    },
    sectionTitle: {
      display: 'inline-flex',
    },
    dialogTitle: {
      alignItems: 'start',
    },
    metadataInfoIcon: {
      position: 'relative',
      top: theme.spacing(0.5),
    },
  }),
);

export interface FeatureFlagRegionVisibilityDialogProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (
    flagKey: string,
    defaultValue?: string,
    clearFlagValues?: boolean,
    visibility?: FeatureFlagVisibility,
  ) => void;
  selectedRegion: string;
  tenants: TenantData[];
  flagName: string;
  batchUpdateState: (isLoading?: boolean, response?: any, error?: Error) => void;
}

export const FeatureFlagRegionsDialog: React.FC<FeatureFlagRegionVisibilityDialogProps> = (
  props,
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const { idToken } = useAuthContext();
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const {
    flagDefinition,
    isDialogOpen,
    dialogToggle,
    updateFeatureFlagDefinition,
    tenants,
    selectedRegion,
    flagName,
    batchUpdateState,
  } = props;
  const { flagKey, visibility } = flagDefinition;
  const [currentFlagValue, setValue] = React.useState<string>();
  const [currentAdminPermission, setCurrentAdminPermission] = React.useState<string>();
  const selectedRegionTenants = tenants.filter((tenant) => tenant.region === selectedRegion);
  const [batchUpdateLoading, setBatchUpdateLoading] = React.useState<boolean>(false);
  const isEditingDisabled = visibility.visible;

  function getNewVisibility(
    flagVisibility: FeatureFlagVisibility,
    selectedRegion: string,
    isVisible: boolean,
  ) {
    const tenants = flagVisibility?.criteria?.tenants ?? [];
    const fleets = flagVisibility?.criteria?.fleets ?? [];
    let regions = flagVisibility?.criteria?.regions ?? [];
    regions = [...regions.filter((region) => region !== selectedRegion)];
    const releaseVersion = flagVisibility?.criteria?.releaseVersion;
    if (isVisible) {
      regions.push(selectedRegion);
    }
    const newVisibility: FeatureFlagVisibility = {
      visible: flagVisibility.visible,
      criteria: {
        ...(tenants.length && { tenants }),
        ...(regions.length && { regions }),
        ...(fleets.length && { fleets }),
        ...(releaseVersion && { releaseVersion }),
      },
    };
    return newVisibility;
  }

  function handleClearValues() {
    setValue(undefined);
    setCurrentAdminPermission(undefined);
  }

  function handleOnClose() {
    handleClearValues();
    dialogToggle(false);
  }

  function handleOnChangeFlagStatus(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handleOnChangeAdminPermissions(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentAdminPermission(event.target.value);
  }

  function handleOnSubmit() {
    let locked;
    let newVisibility: FeatureFlagVisibility | undefined;
    if (currentAdminPermission && !isEditingDisabled) {
      switch (currentAdminPermission) {
        case 'None': {
          newVisibility = getNewVisibility(visibility, selectedRegion, false);
          break;
        }
        case 'CanView': {
          locked = true;
          newVisibility = getNewVisibility(visibility, selectedRegion, true);
          break;
        }
        case 'CanEdit': {
          locked = false;
          newVisibility = getNewVisibility(visibility, selectedRegion, true);
          break;
        }
        default: {
          throw new Error(`Unexpected target value ${currentAdminPermission}`);
        }
      }
    }
    const selectedRegionTenantIds: string[] = [];
    selectedRegionTenants.forEach((tenant) => {
      selectedRegionTenantIds.push(tenant.id);
    });

    if ((currentFlagValue || locked !== undefined) && selectedRegionTenantIds) {
      setBatchUpdateLoading(true);
      batchUpdateState(true);
      putTenantsFlagValue(
        idToken as string,
        flagKey,
        selectedRegion,
        selectedRegionTenantIds,
        'Tenant',
        currentFlagValue,
        locked,
      )
        .then((response) => {
          batchUpdateState(false, response);
        })
        .catch((error) => {
          batchUpdateState(false, undefined, error);
        })
        .finally(() => {
          setBatchUpdateLoading(false);
        });
    }

    if (newVisibility) {
      updateFeatureFlagDefinition(flagKey, undefined, undefined, newVisibility);
    }

    handleClearValues();
  }

  let flagValueOptions;
  if (flagDefinition.possibleValues.type.toLowerCase() === 'list') {
    flagValueOptions = (
      <>
        {flagDefinition.possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => (
          <FormControlLabel
            key={`${option.value}-option`}
            value={option.value.toLowerCase()}
            className={classes.radioButton}
            checked={currentFlagValue === option.value.toLowerCase()}
            control={<Radio />}
            label={option.value.toLowerCase()}
          />
        ))}
      </>
    );
  } else {
    flagValueOptions = (
      <>
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'false'}
          value="false"
          control={<Radio />}
          label={t('global.off')}
        />
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'true'}
          value="true"
          control={<Radio />}
          label={t('global.on')}
        />
      </>
    );
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      data-testid="feature-flags-region-visibility-dialogue"
    >
      <FeatureFlagValuesFollowupDialog isDialogOpen={batchUpdateLoading} />
      <DialogTitle
        className={classes.dialogTitle}
        onClose={handleOnClose}
        id="feature-flags-region-dialog-title"
      >
        {t('featureFlagGeneral.manageSettings', { flagName, item: selectedRegion })}
      </DialogTitle>
      <DialogContent id="feature-flags-region-visibility-dialogue-content">
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-region-dialogue-feature-status-subtitle"
            >
              {t('tenantFeatureFlags.featureStatus')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Feature Status',
                criteria: 'region',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-feature-status"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-region-dialogue-feature-status-options"
            name="region-edit-feature-status"
            onChange={handleOnChangeFlagStatus}
          >
            {flagValueOptions}
          </RadioGroup>
        </Fieldset>
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-region-dialogue-flag-visibility-subtitle"
            >
              {t('featureFlagGeneral.clientAdminPermissions')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Client Admin Permissions',
                criteria: 'region',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-client-admin-permissions"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-region-dialogue-flag-visibility-options"
            name="region-edit-visibility"
            onChange={handleOnChangeAdminPermissions}
          >
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'None'}
              disabled={isEditingDisabled}
              value="None"
              control={<Radio />}
              label={t('featureFlagGeneral.none')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanView'}
              value="CanView"
              control={<Radio />}
              label={t('featureFlagGeneral.canView')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanEdit'}
              value="CanEdit"
              control={<Radio />}
              label={t('featureFlagGeneral.canEdit')}
            />
          </RadioGroup>
        </Fieldset>
      </DialogContent>
      <DialogActions
        id="feature-flags-region-visibility-dialogue-actions"
        tertiaryContent={
          <OutlineButton onClick={handleClearValues}> {t('global.reset')}</OutlineButton>
        }
      >
        <DefaultButton
          id="feature-flags-region-visibility-dialogue-cancel-btn"
          onClick={handleOnClose}
        >
          {t('global.cancel')}
        </DefaultButton>
        <PrimaryButton
          id="feature-flags-region-visibility-dialogue-submit-btn"
          onClick={handleOnSubmit}
          disabled={currentAdminPermission === undefined && currentFlagValue === undefined}
        >
          {t('global.save')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
