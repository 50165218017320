import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import {
  TableRow,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  IconButton,
  Typography,
} from '@bb-ui/react-library/dist/components';
import { PageTemplate } from 'components/PageTemplate';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage';
import { PaginatedTable } from 'components/PaginatedTable';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { FleetData } from 'App.types';
import { Link } from 'react-router-dom';
import { CreateFleetButton } from './CreateFleetButton';
import { ChevronRight } from '@bb-ui/icons/dist/small';

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.3rem',
    justifyContent: 'center',
  },
  enabledCell: {
    width: 20,
  },
}));

export const Fleets: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, error, loading, fetch: fleetFetch } = useRestApi(apiUrl('tenancy', 'fleets'));

  const handleReload = () => {
    fleetFetch();
  };

  const fleets = React.useMemo<FleetData[]>(() => {
    return (data?.items ?? []).filter(
      (fleet: FleetData) => !fleet.name.startsWith('Integration Test Fleet:'),
    );
  }, [data]);

  const search =
    (filter: string) =>
    ({ name, region }: FleetData) =>
      name.toLowerCase().includes(filter.toLowerCase()) ||
      region.toLowerCase().includes(filter.toLowerCase());

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});

  const sortedFleets = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    const getComparator = (sortColumnId: string | undefined) => {
      switch (sortColumnId) {
        case 'fleetName':
          return (l1: { name: string }, l2: { name: string }) =>
            orderSign * l1.name.localeCompare(l2.name);
        default:
          return null;
      }
    };

    const comparator = getComparator(sortColumnId);

    if (comparator) {
      return [...fleets].sort(comparator);
    }

    return fleets;
  }, [fleets, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`fleetService.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (loading) {
    content = <LoadingIndicator data-testid="fnds-fleet-service-init" />;
  } else if (error) {
    content = (
      <ErrorMessage
        title={t('fleetService.loadError')}
        message={error.message}
        data-testid="fnds-fleet-service-no-data"
      />
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('fleetService.searchLabel') }}
        sortedData={sortedFleets}
        search={search}
        noMatchesMessage={(searchExpression) => t('fleetService.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="fleet-table-header-name"
                columnId="fleetName"
                tooltip={t('fleetService.sortByFleetName')}
              >
                {t('fleetService.name')}
              </SortableTableHeaderCell>
              <TableCell id="fleet-table-header-fleetId">{t('fleetService.fleetId')}</TableCell>
              <TableCell id="fleet-table-header-region">{t('fleetService.region')}</TableCell>
              <TableCell id="fleet-table-header-stage">{t('fleetService.stage')}</TableCell>
              <TableCell id="fleet-table-header-accountId">{t('fleetService.accountId')}</TableCell>
              <TableCell id="fleet-table-header-isIl4">{t('fleetService.isIL4')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
        )}
        renderRow={(fleet, index) => (
          <TableRow
            key={fleet.name}
            aria-rowindex={index + 1}
            data-testid={`fleet-service-table-row-${fleet.name}`}
          >
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-fleet_name">
              {fleet.name}
            </TableCell>
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-fleet_id">
              {fleet.fleetId}
            </TableCell>
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-fleet_region">
              {fleet.region}
            </TableCell>
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-stage">
              {fleet.stage}
            </TableCell>
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-account_id">
              {fleet.accountId}
            </TableCell>
            <TableCell aria-colindex={1} aria-describedby="fleet-table-header-fleet_isIl4">
              {fleet.isIL4 ? 'Yes' : 'No'}
            </TableCell>
            <TableCell aria-colindex={6} tabIndex={-1} className={classes.enabledCell}>
              <LooseIconButton
                aria-label={t('tenantList.moreInformation')}
                component={Link}
                to={`/fleets/${fleet.id}/configuration`}
              >
                <Typography component="span" variant="inherit">
                  {t('global.view')}
                </Typography>
                <ChevronRight />
              </LooseIconButton>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return (
    <>
      <PageTemplate
        showTabs
        title={t('fleetService.pageTitle')}
        data-testid="fleet-service-page"
        headerControl={<CreateFleetButton onReload={handleReload} />}
      >
        {content}
      </PageTemplate>
    </>
  );
};

export default Fleets;
