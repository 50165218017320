import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, makeStyles, createStyles, Typography } from '@bb-ui/react-library';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Table, TableBody, TableRow, TableCell } from '@bb-ui/react-library/dist/components';
import { Checkbox } from '@bb-ui/react-library/dist/components/Checkbox';
import { SearchBox } from '@bb-ui/react-library/dist/components/SearchBox';
import { useSnackbar } from 'hooks/useSnackbar';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { orderBy, chunk } from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    addGroupInput: {
      margin: theme.spacing(2, 0, 1),
    },
    tableContainer: {
      maxHeight: '75vh',
      overflowY: 'auto',
    },
    row: {
      display: 'flex',
    },
    clickableCell: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: '28rem',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  });

export const useStyles = makeStyles(styles);

export interface CreateGroupDialogProps extends DialogProps {
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateGroupDialog: React.FunctionComponent<CreateGroupDialogProps> = ({
  id,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('sso', 'fndsGroups'), { manual: true });
  const { data: permissionsData, fetch: fetchPermissions } = useRestApi(
    apiUrl('authz', 'permissions'),
    { manual: true },
  );

  React.useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const permissions = React.useMemo<string[]>(() => {
    if (Array.isArray(permissionsData)) {
      return permissionsData;
    }
    return permissionsData?.permissions ?? [];
  }, [permissionsData]);

  const [groupName, setGroupName] = React.useState('');
  const [groupType, setGroupType] = React.useState('');
  const [groupDesc, setGroupDesc] = React.useState('');
  const [selectedPermissions, setSelectedPermissions] = React.useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = React.useState('');
  const [showPermissionsModal, setShowPermissionsModal] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setSelectedPermissions(new Set());
      setGroupName('');
      setGroupType('');
      setGroupDesc('');
      setShowErrors(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('createGroupDialog.createSucceeded', { name: groupName }), {
        variant: 'success',
      });
      clearSucceededRequests();
      onSuccess();
      onClose();
    }
  }, [
    succeededRequests,
    clearSucceededRequests,
    enqueueSnackbar,
    onClose,
    groupName,
    t,
    onSuccess,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('createGroupDialog.createFailed', {
          message: failedRequests[0].error.message,
          name: groupName,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      onClose();
    }
  }, [failedRequests, clearFailedRequests, enqueueSnackbar, onClose, groupName, t]);

  const filteredPermissions = React.useMemo(() => {
    const sortedPermissions = orderBy(permissions, [(perm: string) => perm.toLowerCase()], ['asc']);
    return chunk(
      sortedPermissions.filter((perm: string) =>
        perm.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
      4,
    );
  }, [searchTerm, permissions]);

  const togglePermission = (perm: string) => {
    setSelectedPermissions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(perm)) {
        newSet.delete(perm);
      } else {
        newSet.add(perm);
      }
      return newSet;
    });
  };

  const createGroup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrors(true);
    if (!groupName || !groupType || !groupDesc) return;
    doPost({
      name: groupName.trim(),
      type: groupType.trim(),
      description: groupDesc.trim(),
      permissions: Array.from(selectedPermissions),
    });
  };

  return (
    <Dialog id={id} open={open} onClose={onClose} scroll="body">
      <DialogTitle onClose={onClose}>{t('createGroupDialog.title')}</DialogTitle>
      <form role="form" onSubmit={createGroup} noValidate>
        <DialogContent>
          <TextField
            id="groupName"
            className={classes.addGroupInput}
            label={t('createGroupDialog.groupName')}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            required
            error={showErrors && !groupName}
          />
          <TextField
            id="groupType"
            className={classes.addGroupInput}
            label={t('createGroupDialog.groupType')}
            value={groupType}
            onChange={(e) => setGroupType(e.target.value)}
            fullWidth
            required
            error={showErrors && !groupType}
          />
          <TextField
            id="groupDesc"
            className={classes.addGroupInput}
            label={t('createGroupDialog.groupDesc')}
            value={groupDesc}
            onChange={(e) => setGroupDesc(e.target.value)}
            fullWidth
            multiline
            required
            error={showErrors && !groupDesc}
          />
          <DefaultButton onClick={() => setShowPermissionsModal(true)}>
            {`${t('createGroupDialog.selectPermissions')} (${selectedPermissions.size})`}
          </DefaultButton>
          <Typography variant="body2" color="textSecondary">
            {selectedPermissions.size > 0
              ? `${selectedPermissions.size} ${t('createGroupDialog.permissionsSelected')}`
              : `${t('createGroupDialog.noPermissionsSelected')}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={onClose}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton type="submit" disabled={loadingRequests}>
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
      <Dialog
        open={showPermissionsModal}
        onClose={() => setShowPermissionsModal(false)}
        maxWidth="xl"
      >
        <DialogTitle onClose={() => setShowPermissionsModal(false)}>
          {`${t('createGroupDialog.selectPermissions')} (${selectedPermissions.size})`}
        </DialogTitle>
        <DialogContent className={classes.tableContainer}>
          <SearchBox
            inputId="search-permissions"
            label={t('createGroupDialog.searchPermissions')}
            value={searchTerm}
            onChange={setSearchTerm}
            onSearch={setSearchTerm}
          />
          <Table>
            <TableBody>
              {filteredPermissions.map((row, rowIndex) => (
                <TableRow key={rowIndex} className={classes.row}>
                  {row.map((perm) => (
                    <TableCell
                      key={perm}
                      className={classes.clickableCell}
                      onClick={() => togglePermission(perm)}
                    >
                      <Checkbox
                        checked={selectedPermissions.has(perm)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => togglePermission(perm)}
                      />
                      {perm}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};
