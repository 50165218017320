import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy, get } from 'lodash';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageTemplate } from 'components/PageTemplate';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { PaginatedTable } from 'components/PaginatedTable';
import { FoundationsGroup } from 'pages/TenantIdp/GroupMappingCard/GroupMapping.types';
import { AddGroupButton } from './AddGroupButton';
import { EditGroupButton } from './EditGroupButton';

export const GroupList: React.FunctionComponent = () => {
  const { data, error, loading, fetch } = useRestApi(apiUrl('sso', 'fndsGroups'));
  const groups = React.useMemo<FoundationsGroup[]>(
    () =>
      (data?.results ?? []).filter(
        (group: FoundationsGroup) => group.type.toLowerCase() !== 'test',
      ),
    [data],
  );
  const { t } = useTranslation();

  const search =
    (filter: string) =>
    ({ name }: FoundationsGroup) =>
      name.toLowerCase().includes(filter.toLowerCase());

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedGroups = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (sortColumnId && sortDirection && ['name', 'type'].includes(sortColumnId)) {
      return orderBy(groups, [(group) => get(group, sortColumnId).toLowerCase()], sortDirection);
    }
    return groups;
  }, [groups, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`groupList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (loading) {
    content = <LoadingIndicator data-testid="fnds-group-list-init" />;
  } else if (groups.length > 0) {
    content = (
      <div data-testid="fnds-group-list-data-table">
        <PaginatedTable
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('groupList.searchLabel') }}
          sortedData={sortedGroups}
          search={search}
          noMatchesMessage={(searchExpression) => t('groupList.noMatch', { searchExpression })}
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="group-list-table-header-name"
                  columnId="name"
                  tooltip={t('groupList.sortByName')}
                >
                  {t('groupList.name')}
                </SortableTableHeaderCell>
                <TableCell
                  id="group-list-table-header-description"
                  tabIndex={-1}
                  role="columnheader"
                >
                  {t('groupList.description')}
                </TableCell>
                <SortableTableHeaderCell
                  id="group-list-table-header-type"
                  columnId="type"
                  tooltip={t('groupList.sortByType')}
                >
                  {t('groupList.type')}
                </SortableTableHeaderCell>
                <TableCell id="group-list-table-header-edit" tabIndex={-1} role="columnheader">
                  {t('groupList.edit')}
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(group, index) => (
            <TableRow
              key={group.name}
              aria-rowindex={index + 1}
              data-testid={`group-list-table-row-${group.name}`}
            >
              <TableCell aria-colindex={1} tabIndex={-1}>
                {group.name}
              </TableCell>
              <TableCell aria-colindex={2} tabIndex={-1}>
                {group.description}
              </TableCell>
              <TableCell aria-colindex={3} tabIndex={-1}>
                {group.type}
              </TableCell>
              <TableCell aria-colindex={4} tabIndex={-1}>
                <EditGroupButton group={group} />
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    );
  } else {
    content = (
      <Typography data-testid="fnds-group-list-no-data">{t('groupList.noData')}</Typography>
    );
  }

  return (
    <PageTemplate
      showTabs
      headerControl={<AddGroupButton onSuccess={fetch} />}
      title={t('groupList.pageTitle')}
      errorMessage={error && { title: t('groupList.loadError'), message: error.message }}
    >
      <div data-testid="group-list-page">{content}</div>
    </PageTemplate>
  );
};
