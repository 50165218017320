import { AddCircle } from '@bb-ui/icons/dist/medium/AddCircle';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateGroupDialog } from 'dialogs/CreateGroupDialog';
import { useAuthorization } from 'hooks/useAuthorization';

export interface AddGroupButtonProps {
  onSuccess: () => void;
}

export const AddGroupButton: React.FunctionComponent<AddGroupButtonProps> = ({ onSuccess }) => {
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const [groupDialogOpen, setGroupDialogOpen] = React.useState(false);

  if (!hasPermission('fnds-group', 'create')) {
    return null;
  }

  return (
    <>
      <PrimaryButton
        startIcon={<AddCircle />}
        data-testid="header-add-button"
        onClick={() => setGroupDialogOpen(true)}
      >
        {t('groupList.addNew')}
      </PrimaryButton>
      <CreateGroupDialog
        id="create-group-dialog"
        open={groupDialogOpen}
        onClose={() => setGroupDialogOpen(false)}
        onSuccess={() => {
          onSuccess();
          setGroupDialogOpen(false);
        }}
      />
    </>
  );
};
