import React, { ReactNode } from 'react';
import {
  Combobox,
  ComboboxOption,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  DialogActions,
  DefaultButton,
  PrimaryButton,
} from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import Form from '@rjsf/material-ui';
import { IJobConfig, JobDetailResponseBody, ProviderRegistration } from '@lct-jobs/core';
import { ISubmitEvent } from '@rjsf/core';
import useRestApi from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';
import { useAppConfigContext } from 'contexts/AppConfigContext';

export interface JobSubmitEvent extends ISubmitEvent<any> {
  selectedProvider?: ProviderRegistration;
}

export interface RunDialogProps {
  job: IJobConfig;
  tenantId: string;
  closeDialog: () => void;
  submitJob: (s: JobSubmitEvent) => void;
}

type ProviderComboboxOption = ComboboxOption & {
  provider?: ProviderRegistration;
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      '& div[role="radiogroup"] label span:first-child': {
        width: '32px',
        height: '32px',
        borderRadius: '16px',
        '&:hover': {
          backgroundColor: theme.palette.background.b4,
        },
      },
    },
    loadingText: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

const confirmationJobIds = [
  'DecommissionLearnInstaller',
  'DecommissionLearnInstance',
  'DeleteCname',
  'DeleteDDA',
  'DeleteDDAUser',
];

export const RunDialog: React.FC<RunDialogProps> = (props) => {
  const { t } = useTranslation();
  const { job } = props;
  const env = useAppConfigContext();
  const classes = useStyles(props);
  const [open, setOpen] = React.useState<boolean>(true);
  const [providerOptions, setProviderOptions] = React.useState<ProviderComboboxOption[]>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<ProviderComboboxOption | undefined>();
  const [updatedSchema, setUpdatedSchema] = React.useState<any>(job.inputSchema);
  const [formData, setFormData] = React.useState<any>({});
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [pendingSubmit, setPendingSubmit] = React.useState<JobSubmitEvent | null>(null);

  const { fetch, loading, data } = useRestApi<JobDetailResponseBody>(
    apiUrl('lct', `tenants/${props.tenantId}/jobManager/jobs/${props.job.jobId}/providers`)
  );

  const [isPolling, setIsPolling] = React.useState(true);
  const intervalIdRef = React.useRef<NodeJS.Timeout | null>(null);

  const environment = env.supportRedirectUrl?.match(/https:\/\/([^.]*)\./)?.[1];

// Function to update properties conditionally based on environment rules
  const applyEnvironmentConditionalRules = (
    properties: Record<string, any>,
    env: string
  ) => {
    const newProperties = { ...properties };
    for (const [propKey, property] of Object.entries(newProperties)) {
      if (property.conditionalField) {
        const { allowedEnvironments } = property.conditionalField;
        const isEnvAllowed =
          Array.isArray(allowedEnvironments) &&
          allowedEnvironments
            .map((envStr: string) => envStr.toLowerCase())
            .includes(env.toLowerCase());
        if (!isEnvAllowed) {
          delete newProperties[propKey];
        }
      }
    }
    return newProperties;
  };

  const updatePropertiesWithResolvedKeys = (
    resolvedKeys: Record<string, any>,
    properties: Record<string, any>
  ) => {
    for (const [key, resolvedKey] of Object.entries(resolvedKeys)) {
      for (const [propKey, property] of Object.entries(properties)) {
        if (property.autofillKey === key) {
          if (resolvedKey.defaultValue) {
            property.default = resolvedKey.defaultValue;
          }
          if (resolvedKey.examples) {
            property.examples = resolvedKey.examples;
          }
          if (resolvedKey.enum) {
            property.default = Object.keys(resolvedKey.enum)[0];
            if (resolvedKey.enum[property.default][propKey] !== property.default) {
              property.oneOf = Object.keys(resolvedKey.enum).map((enumKey) => ({
                title: enumKey,
                const: resolvedKey.enum[enumKey][propKey],
              }));
            } else {
              property.enum = Object.keys(resolvedKey.enum);
            }
          }
        }
      }
    }
  };

  const updateFormDataWithEnum = (
    properties: Record<string, any>,
    formData: any,
    newFormData: any,
    resolvedKeys: Record<string, any>
  ) => {
    const updatedFormData = { ...newFormData }; // Create a copy of newFormData
    for (const [fieldKey, property] of Object.entries(properties)) {
      const autofillKey = property.autofillKey;
      if (autofillKey && resolvedKeys?.[autofillKey] && formData[fieldKey]) {
        const resolvedKey = resolvedKeys[autofillKey];
        const selectedValue = formData[fieldKey];
        const enumValue = resolvedKey.enum?.[selectedValue];
        if (enumValue) {
          for (const [enumFieldKey, value] of Object.entries(enumValue)) {
            updatedFormData[enumFieldKey] = value;
          }
        }
      }
    }
    return updatedFormData;
  };

  // useEffect for polling providers data
  React.useEffect(() => {
    const pollInterval = 10000; // Poll every 10 seconds
    const pollDuration = 60000; // Stop polling after 1 minute

    intervalIdRef.current = setInterval(async () => {
      if (isPolling && data?.outstandingAutoFillKeys && data.outstandingAutoFillKeys.length > 0) {
        await fetch();
      } else if (data?.outstandingAutoFillKeys && data.outstandingAutoFillKeys.length === 0) {
        setIsPolling(false);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      }
    }, pollInterval);

    const timeoutId = setTimeout(() => {
      setIsPolling(false);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }, pollDuration);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      clearTimeout(timeoutId);
    };
  }, [isPolling, fetch, data]);

  // useEffect to close the dialog when open state is false
  React.useEffect(() => {
    if (!open) {
      props.closeDialog();
    }
  }, [open, props]);

  // useEffect to update provider options when provider data changes
  React.useEffect(() => {
    const providers: ProviderRegistration[] = Object.values(data?.providers ?? {});
    const providerOpts = providers.map((provider: ProviderRegistration) => ({
      label: provider.displayName ?? `${provider.region} ${provider.deployment ?? ''}`,
      value: provider.providerId,
      provider,
    }));
    setProviderOptions(providerOpts);
  }, [data]);

  // useEffect to update the JSON schema based on resolved keys and environment conditions
  React.useEffect(() => {
    if (data?.resolvedAutoFillKeys && job.inputSchema && (job.inputSchema as any).properties) {
      const inputSchemaWithProperties = job.inputSchema as { properties: { [key: string]: any } };
      const newSchema = {
        ...inputSchemaWithProperties,
        properties: { ...inputSchemaWithProperties.properties },
      };

      if (environment && newSchema.properties) {
        newSchema.properties = applyEnvironmentConditionalRules(newSchema.properties, environment);
      }

      if (data.resolvedAutoFillKeys && newSchema.properties) {
        updatePropertiesWithResolvedKeys(data.resolvedAutoFillKeys, newSchema.properties);
      }

      setUpdatedSchema(newSchema);
    }
  }, [data, job.inputSchema, environment]);

  const handleFormChange = ({ formData }: { formData: any }) => {
    setFormData((prevFormData: any) => {
      const newFormData = { ...prevFormData, ...formData };
      const schemaWithProperties = updatedSchema as { properties?: Record<string, any> };
      if (!schemaWithProperties.properties) return newFormData;

      const updated = updateFormDataWithEnum(
        schemaWithProperties.properties,
        formData,
        newFormData,
        data?.resolvedAutoFillKeys ?? {}
      );
      return updated;
    });
  };

  const handleSubmit = (s: ISubmitEvent<any>) => {
    const requiresConfirmation = confirmationJobIds.includes(props.job.jobId);
    if (requiresConfirmation) {
      setPendingSubmit({
        ...s,
        selectedProvider: selectedProvider?.provider,
      });
      setConfirmOpen(true);
    } else {
      props.submitJob({
        ...s,
        selectedProvider: selectedProvider?.provider,
      });
      setOpen(false);
    }
  };

  const handleConfirmSubmit = () => {
    if (pendingSubmit) {
      props.submitJob(pendingSubmit);
      setConfirmOpen(false);
      setPendingSubmit(null);
      setOpen(false);
    }
  };

  const handleCancelSubmit = () => {
    setConfirmOpen(false);
    setPendingSubmit(null);
  };

  return (
    <>
      <Dialog id="run-dialog" open={open} onClose={() => setOpen(false)}>
        <DialogTitle onClose={() => setOpen(false)}>{job.jobName}</DialogTitle>
        <DialogContent>
          {environment === 'dev' && loading && (
            <Typography variant="body2" color="error" className={classes.loadingText}>
              {t('jobService.loadingProviders')}
            </Typography>
          )}
          {environment === 'dev' && (
            <Combobox
              floatingLabel
              id="combobox-single"
              label="Select a Provider"
              placeholder="Default Provider"
              strings={{
                announceOptionSelected(option: ComboboxOption): string {
                  return `${option.label}, selected.`;
                },
                announceOptionDeselected(option: ComboboxOption): string {
                  return `${option.label}, deselected.`;
                },
                announceValueCleared: 'All values deselected.',
                announceSearchResults(count: number, searchString: string): string {
                  return `${count} results found for "${searchString}". Use up and down arrow keys to navigate. Press Enter to select.`;
                },
                clearButtonLabel: 'Clear',
                searchLabel: 'Search',
                noResults(searchString: string): ReactNode {
                  return `No results found for "${searchString}"`;
                },
              }}
              options={providerOptions}
              onChange={setSelectedProvider}
              isLoading={loading}
              isClearable={true}
            />
          )}
          <Form
            schema={updatedSchema}
            formData={formData}
            onChange={handleFormChange}
            onSubmit={handleSubmit}
            uiSchema={job.inputUiSchema}
            className={classes.form}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={handleCancelSubmit}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, .75)' },
        }}
      >
        <DialogTitle onClose={handleCancelSubmit}>
          {t('jobService.confirmSubmitTitle', { jobName: job.jobName })}
        </DialogTitle>
        <DialogActions>
          <DefaultButton onClick={handleCancelSubmit}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton onClick={handleConfirmSubmit}>{t('global.submit')}</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
