import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { PaginatedTable } from 'components/PaginatedTable';
import { apiUrl } from 'utils/apiUrl';
import useRestApi from 'hooks/useRestApi';
import { PageTemplate } from 'components/PageTemplate';
import { get, orderBy } from 'lodash';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useHistory } from 'react-router-dom';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { IconButton } from '@bb-ui/react-library';
import { LearnInstance } from '@bb-learn-instances-service/common';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    filtersContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: 'center',
    },
    regionSelect: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

type ExtendedLearnInstance = LearnInstance & {
  Name?: string;
};

export const LearnSites: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const {
    data: siteData,
    loading: loadSiteData,
    error,
    fetch: fetchLearnSites,
  } = useRestApi(apiUrl('lct', 'instances/learnInstances'));

  const siteItems = siteData?.items ?? [];
  const sites = React.useMemo<ExtendedLearnInstance[]>(() => siteItems ?? [], [siteItems]);

  const fetchLearnSitesData = React.useCallback(() => {
    fetchLearnSites();
  }, [fetchLearnSites]);

  React.useEffect(() => {
    fetchLearnSitesData();
  }, [fetchLearnSitesData]);

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedSites = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (
      sortColumnId &&
      sortDirection &&
      ['InstallationId', 'Status', 'Name', 'FleetId', 'Url'].includes(sortColumnId)
    ) {
      return orderBy(
        sites,
        [(site) => get(site, sortColumnId)?.toLowerCase() ?? ''],
        sortDirection,
      );
    }
    return sites;
  }, [sites, sortParams]);

  const search =
    (filter: string) =>
    ({ InstallationId }: LearnInstance) =>
      !!InstallationId && InstallationId.toLowerCase().includes(filter.toLowerCase());

  const [selectedRegion, setSelectedRegion] = React.useState<string>('');
  const [selectedStatus, setSelectedStatus] = React.useState<string>('Installed');

  const regionOptions = React.useMemo(() => {
    const regions = sites.map((site) => site.Region);
    return Array.from(new Set(regions)).sort();
  }, [sites]);

  const statusOptions = React.useMemo(() => {
    const statuses = sites.map((site) => site.Status);
    return Array.from(new Set(statuses)).sort();
  }, [sites]);

  React.useEffect(() => {
    if (regionOptions.length > 0 && selectedRegion === '') {
      setSelectedRegion(regionOptions[0]);
    }
  }, [regionOptions, selectedRegion]);

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRegion(event.target.value);
  };

  const filteredSites = React.useMemo(() => {
    return sortedSites.filter((site) => {
      const matchesRegion = site.Region === selectedRegion;
      const matchesStatus = selectedStatus === 'All' || site.Status === selectedStatus;
      return matchesRegion && matchesStatus;
    });
  }, [selectedRegion, sortedSites, selectedStatus]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`sitesList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (error) {
    content = (
      <ErrorMessage
        data-testid="learn-site-list-error"
        title={t('learnSites.loadError')}
        message={error.message}
        variant="block"
      />
    );
  } else if (loadSiteData) {
    content = <LoadingIndicator data-testid="site-list-init" />;
  } else if (sites.length === 0) {
    content = <Typography data-testid="learn-list-no-data">{t('learnSites.noData')}</Typography>;
  } else {
    content = (
      <>
        <div className={classes.filtersContainer}>
          <TextField
            select
            id="region-select"
            label={t('learnSites.regionSelectLabel')}
            value={selectedRegion}
            onChange={handleRegionChange}
            className={classes.regionSelect}
            floatingLabel
          >
            {regionOptions.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            id="status-select"
            label={t('learnSites.selectStatusLabel')}
            value={selectedStatus}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedStatus(event.target.value)
            }
            className={classes.regionSelect}
            floatingLabel
          >
            <MenuItem value="All">{t('learnSites.all')}</MenuItem>
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <PaginatedTable
          onSortChanged={(newSortParams) => {
            setSortParams(newSortParams);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('learnSites.searchLabel') }}
          sortedData={filteredSites}
          search={search}
          noMatchesMessage={(searchExpression) => t('learnSites.noMatch', { searchExpression })}
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="site-list-table-header-name"
                  columnId="Name"
                  tooltip={t('learnSites.sortByName')}
                >
                  {t('learnSites.name')}
                </SortableTableHeaderCell>

                <SortableTableHeaderCell
                  id="site-list-table-header-installation-id"
                  columnId="InstallationId"
                  tooltip={t('learnSites.sortByInstallationId')}
                >
                  {t('learnSites.installationId')}
                </SortableTableHeaderCell>

                <SortableTableHeaderCell
                  id="site-list-table-header-status"
                  columnId="Status"
                  tooltip={t('learnSites.sortByStatus')}
                >
                  {t('learnSites.status')}
                </SortableTableHeaderCell>

                <SortableTableHeaderCell
                  id="site-list-table-header-fleet-id"
                  columnId="FleetId"
                  tooltip="Fleet ID"
                >
                  {t('learnSites.fleet')}
                </SortableTableHeaderCell>

                <SortableTableHeaderCell
                  id="site-list-table-header-url"
                  columnId="Url"
                  tooltip="URL"
                >
                  {t('learnSites.url')}
                </SortableTableHeaderCell>

                <TableCell role="columnheader" aria-colindex={6} />
              </TableRow>
            </TableHead>
          )}
          renderRow={({ InstallationId, Name, Status, TenantId, FleetId, Url }, index) => (
            <TableRow
              key={InstallationId}
              aria-rowindex={index + 1}
              data-testid={`site-list-table-row-${index}`}
            >
              <TableCell
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="site-list-table-header-name"
              >
                {Name || '-'}
              </TableCell>

              <TableCell
                aria-colindex={2}
                tabIndex={-1}
                aria-describedby="site-list-table-header-installation-id"
              >
                {InstallationId}
              </TableCell>

              <TableCell
                aria-colindex={3}
                tabIndex={-1}
                aria-describedby="site-list-table-header-status"
              >
                {Status}
              </TableCell>

              <TableCell
                aria-colindex={4}
                tabIndex={-1}
                aria-describedby="site-list-table-header-fleet-id"
              >
                {FleetId || '-'}
              </TableCell>

              <TableCell
                aria-colindex={5}
                tabIndex={-1}
                aria-describedby="site-list-table-header-url"
              >
                {Url || '-'}
              </TableCell>

              <TableCell
                aria-colindex={6}
                tabIndex={-1}
                aria-describedby="site-list-table-header-actions"
                align="right"
              >
                <IconButton
                  aria-label={t('tenantList.moreInformation')}
                  onClick={() => history.push(`/learn-site/${TenantId}/information`)}
                >
                  <Typography component="span" variant="inherit">
                    {t('global.view')}
                  </Typography>
                  <ChevronRight />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        />
      </>
    );
  }

  return (
    <PageTemplate showTabs title={t('learnSites.pageTitle')} data-testid="license-list-page">
      {content}
    </PageTemplate>
  );
};
